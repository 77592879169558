import React from 'react'
import Text from 'components/shared/Typography/v2/Text'
import styles from './primaryheader.module.scss'
import Divider from 'components/shared/Divider/Divider'
import Headline from '../HomepageContent/Headline'
import {
  TrackingLabelType,
  TrackingContextType,
  TrackingAssetType,
  TrackingItemURLType,
} from 'hooks/useAnalytics/types'
import cn from 'classnames'

type TitleType = string
type LinkURLType = string

export interface PrimaryHeaderProps {
  title: TitleType
  description?: string
  dataTestID?: string
  /** url to navigate to when headline is clicked */
  linkUrl?: LinkURLType
  /** label for analytics interaction event when headline is clicked */
  trackingLabel?: TrackingLabelType
  /** context for analytics interaction event when headline is clicked */
  trackingContext?: TrackingContextType
  /** asset type for analytics interaction event when headline is clicked */
  trackingAsset?: TrackingAssetType
  /** item URL type for analytics interaction event when headline is clicked */
  trackingItemURL?: TrackingItemURLType
  showDivider?: boolean
  /** if wrapped in css grid, lay out using grid columns. otherwise defaults to setting margins */
  useGridColumns?: boolean
}

export const PrimaryHeader = ({
  title,
  description,
  linkUrl,
  trackingLabel,
  trackingContext,
  trackingAsset,
  trackingItemURL,
  showDivider = false,
  useGridColumns = false,
  dataTestID,
}: PrimaryHeaderProps) => {
  return (
    <header
      className={cn(styles.primaryHeader, {
        [styles.useGridColumns]: useGridColumns,
      })}
    >
      {showDivider && <Divider borderSize="medium" className={styles.rule} />}
      <Text variant="title-section" element="h2">
        <Headline
          className={styles.headlineLink}
          title={title}
          linkUrl={linkUrl}
          dataTestID={dataTestID}
          trackingLabel={trackingLabel}
          trackingContext={trackingContext}
          trackingAsset={trackingAsset}
          trackingItemURL={trackingItemURL}
        />
      </Text>
      {description && (
        <Text variant="body-long" element="h3" className={styles.description}>
          {description}
        </Text>
      )}
    </header>
  )
}
